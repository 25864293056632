import Header from './header';
import React  from 'react';
import CountDown from 'react-countdown';
const Completionist = () => <span>Sposati il 09-09-2023</span>;
var date = new Date('09/09/2023');
date.setTime(date.getTime() + (15*60*60*1000));

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
};

function Home() {
    return(
      <div>
      <Header></Header> 
        <div className="container">
          <section className="w3-row">
            <div className="w3-content  d-flex wrap f-column align-items-center justify-content-center">

              <h2 className="pt-1">Benvenuti!</h2>
              <p>È proprio vero, ci sposiamo! Ce la stiamo mettendo tutta per organizzare il nostro matrimonio e vogliamo renderlo un giorno <b>divertente e piacevole per tutti</b>. Per questo qui sotto troverete sempre gli <b>ultimi aggiornamenti</b> per sapere come organizzarvi.
              Nelle varie sezioni del sito invece, abbiamo raccolto altre informazioni che speriamo possano esservi utili.</p>
              <div className="divider"></div>
            </div>
          </section>
          <section className='w3-row'>
            <h2>
            <CountDown date={date}>
              <h2><Completionist /></h2>
            </CountDown>
            </h2>
          </section>
          <div className="divider"></div>
        </div>
      </div> 
    )
} export default Home;