import React  from 'react';
import { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyAMtpglxBMQvXZAABpQKPgIWuZ7G4XbssY",
  authDomain: "alessandro-giulia.firebaseapp.com",
  projectId: "alessandro-giulia",
  storageBucket: "alessandro-giulia.appspot.com",
  messagingSenderId: "275746409396",
  appId: "1:275746409396:web:38b78675d1c2f44ce9c0e1"
};

initializeApp(firebaseConfig);
const db = getFirestore();

function Comunicazioni() {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [body, setBody] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const json = {
            'name' : name.trim(),
            'surname' : surname.trim(),
            'body' : body.trim()
        };
        
        if (AddDoc_AutoID(json)) {
            setName('');
            setSurname('');
            setBody('');
        }
    };

    return (
        <div className="container">
            <h2 className="pt-1">Comunicazioni</h2>
            <div className="d-flex width-100 ">
        <div className="border-dotted mt-1 mb-1 width-50">
                <h2>Lo sposo</h2>
                <p><b>Alessandro</b><br></br>+39 340 1619298</p>
                </div>
                <div style={{width:'20px'}}></div>

                <div className="border-dotted mt-1 mb-1 width-50">
                <h2>La sposa</h2>
                <p><b>Giulia</b><br></br>+39 349 3695604</p>
                </div>
                </div>

                <div className="divider"></div>
                <p>Aiutateci a rendere il nostro giorno perfetto per tutti.</p>
                <p>Segnalateci qui sotto eventuali allergie, intolleranze alimentari o la necessità di un menù speciale (vegetariano o vegano).</p>
                <div className='create'>
                    <form id="myForm" onSubmit={handleSubmit}>
                        <label>Nome:</label>
                        <input type='text' required value={name} onChange={(e) => setName(e.target.value)}/>
                        <label>Cognome:</label>
                        <input type='text' required value={surname} onChange={(e) => setSurname(e.target.value)}/>
                        <label>Scrivi qui:</label>
                        <textarea required value={body} onChange={(e) => setBody(e.target.value)}/>
                        <button className='customButton'>Invia</button>
                    </form>
                </div>
                <div className="divider"></div>
            </div>
    )
} export default Comunicazioni;

async function AddDoc_AutoID(json) {
    var ref = collection(db, 'informazioni');
    const date = new Date();
    await addDoc(
        ref,
        {
            Nome: json.name,
            Cognome: json.surname,
            Body: json.body,
            TimeStamp: date.toString()
        }).then(() => {
            return true;
        });
    return false;
}