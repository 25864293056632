import React  from 'react';

function Dove() {
    return (
            <div className="container">
                <h2 className="pt-1">Dove</h2>
                <div className="d-flex wrap justify-content-center">
                    <div class="chiesa-img width-50 width-100">
                    </div>
                    <div>
                        <p>Qui sotto vi lasciamo tutti i luoghi di interesse della giornata.</p>
                        <p>Grazie in anticipo per lo sforzo che farete per essere presenti!</p>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="w3-card-2 wrap" style={{ alignItems: 'center', borderRadius: '4px', padding: '15px', width: '400px', height: '17rem' }}>
                    <div className="">
                        <h3>Parrocchia Sant'Anna dei Boschi</h3>
                        <p>Piazza Sant'Anna 17<br></br>Boschi Sant'Anna, Verona</p>
                        <a href='https://goo.gl/maps/cz1FV9fESYAkhWN66'>Qui</a>
                    </div>
                </div>
                <hr></hr>
                <div className="w3-card-2 wrap" style={{ alignItems: 'center', borderRadius: '4px', padding: '15px', width: '400px', height: '17rem' }}>
                    <div className="">
                        <h3>Agriturismo Tre Rondini</h3>
                        <p>Via Belfiore 47<br></br>Vigo, Verona</p>
                        <a href='https://goo.gl/maps/1Hf1Dr2f6357HqQr8'>Qui</a>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
    )
} export default Dove