import React  from 'react';

function Header() {
  return (
    <header className="App-header">
      <div class="d-flex justify-content-space-around wrap width-100" style={{ paddingleft: '100px' }}>
        <div>
          <div className="SubHeaderTitle"><b>09 Settembre 2023</b></div>
          <h1 className="myHeader"> Alessandro e Giulia</h1>
        </div>
        <div>
          <div className="fotoHeader"></div>
        </div>
      </div>
    </header>
  )
} export default Header;