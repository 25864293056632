import './App.css';
import React  from 'react';

import { BrowserRouter, Switch, Route} from 'react-router-dom';
import Home from './Home'
import Dove from './Dove';
import Ricordi from './Ricordi'
import Comunicazioni from './Comunicazioni'
import Nav from './Nav';

function App() {
  return (
    <div>
    <BrowserRouter>
      <div className="App">
        <Nav></Nav>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/Dove" component={Dove}></Route>
          <Route exact path="/Ricordi" component={Ricordi}></Route>
          <Route exact path="/Comunicazioni" component={Comunicazioni}></Route>
        </Switch>
      </div>
    </BrowserRouter>
    </div>
  );
}

export default App;
