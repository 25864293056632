import React, {useEffect, useState}  from 'react';

function Ricordi(){
    return(
        <div className="container">
            <h2 className="pt-1">Ricordi</h2>

            <div className="d-flex f-column justify-content-center align-items-center">
                <div class="camera-img">
                </div>
                <div>
                    <p>In questa sezione troverete alcune tra le foto più belle del nostro giorno.</p>
                    <p>Tramite il link che pubblicheremo nei giorni successivi al matrimonio potrete rivivere i momenti migliori.</p>
                </div>
            </div>
            <div className="divider"></div>
            <div>
                <p>Puoi trovare le foto in alta qualità dell'evento <a href="https://drive.google.com/drive/folders/1mVUGzKfSUy5CJ9QE4nln4YX7V8fkr8KK?usp=sharing" target='blank'>qui</a></p>
                <p>Qui sotto invece troverai un assaggio della nostra giornata vissuta tramite un breve video</p>
            </div>
            <div className="divider"></div>
            <iframe width="280" height="315"
                src="https://www.youtube.com/embed/00jYLJArkF8?si=VNoKAeipI0DWkr_t"
                title="A&G"
                frameborder="5"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen>
            </iframe>
        </div>
    )
} export default Ricordi;